html, body, #root {
  height: 100%;
}

body {
  margin: 0;
}

@media (max-width: 999px) {
  a.maplibregl-ctrl-logo {
    margin-left: 24px;
  }
}

.maplibregl-ctrl-attrib {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9em;
}

.maplibregl-ctrl-scale {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.map-tooltip {
  z-index: 4;
}

.map-tooltip .maplibregl-popup-content {
  padding: 4px 8px;
}

.map-tooltip .maplibregl-popup-content h3 {
  margin: 0;
}

.map-custom-popup {
  z-index: 2;
}

.map-custom-popup .maplibregl-popup-content {
  padding: 0;
}
